var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.token)?_c('GuestLayout',[_c('v-validate-observe',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"reset-password-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-card',{staticClass:"mx-auto",staticStyle:{"max-width":"24rem"},attrs:{"header":_vm.$t('reset-password.header')}},[(_vm.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('p',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t("reset-password.info"))+" ")]),_c('b-card-text',[_c('v-validate',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var dirty = ref.dirty;
var valid = ref.valid;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('reset-password.email'),"type":"email","state":dirty ? valid : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('v-validate',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var dirty = ref.dirty;
var valid = ref.valid;
return [_c('b-form-input',{staticClass:"mt-3",attrs:{"autocomplete":"new-password","placeholder":_vm.$t('reset-password.password'),"type":"password","state":dirty ? valid : null},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('v-validate',{attrs:{"name":"password_confirmation","rules":"required|password:@password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var dirty = ref.dirty;
var valid = ref.valid;
return [_c('b-form-input',{staticClass:"mt-3",attrs:{"autocomplete":"new-password","placeholder":_vm.$t('reset-password.password-confirm'),"type":"password","state":dirty ? valid : null},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"float-right",attrs:{"disabled":invalid,"variant":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("reset-password.submit")))])],1)],1)]}}],null,false,4253420080)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }