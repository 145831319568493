<template>
  <GuestLayout v-if="token">
    <v-validate-observe v-slot="{ invalid }">
    <form class="reset-password-form" @submit.prevent="submit">
      <b-card
        :header="$t('reset-password.header')"
        style="max-width: 24rem"
        class="mx-auto"
      >
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>

        <p class="small">
          {{ $t("reset-password.info") }}
        </p>

        <b-card-text>
          <v-validate name="email" rules="required|email" v-slot="{ errors, dirty, valid }">
            <b-form-input
              v-model="email"
              :placeholder="$t('reset-password.email')"
              type="email"
              :state="dirty ? valid : null"
            ></b-form-input>
            <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
          </v-validate>
          <v-validate name="password" rules="required|min:6" v-slot="{ errors, dirty, valid }">
            <b-form-input
              autocomplete="new-password"
              v-model="newPassword"
              :placeholder="$t('reset-password.password')"
              type="password"
              class="mt-3"
              :state="dirty ? valid : null"
            ></b-form-input>
            <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
          </v-validate>
          <v-validate name="password_confirmation" rules="required|password:@password" v-slot="{ errors, dirty, valid }">
            <b-form-input
              autocomplete="new-password"
              v-model="newPasswordConfirmation"
              :placeholder="$t('reset-password.password-confirm')"
              type="password"
              class="mt-3"
              :state="dirty ? valid : null"
            ></b-form-input>
            <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
          </v-validate>
        </b-card-text>

        <b-button :disabled="invalid" variant="primary" type="submit" class="float-right">{{
          $t("reset-password.submit")
        }}</b-button>
      </b-card>
    </form>
    </v-validate-observe>
  </GuestLayout>
</template>

<script>
import GuestLayout from "@/layouts/Guest";
import { mapActions } from "vuex";
import { 
  ValidationProvider as vValidate, 
  ValidationObserver as vValidateObserve, 
} from 'vee-validate';

export default {
  components: { GuestLayout, vValidate, vValidateObserve },
  data() {
    return {
      token: this.$route.query.token,
      message: false,
      error: false,
      email: null,
      newPassword: null,
      newPasswordConfirmation: null,
    };
  },
  validationConfig: {
    $autoDirty: true,
    $lazy: true,
  },
  methods: {
    ...mapActions({
      resetPassword: "auth/resetPassword",
    }),
    async submit() {
      this.error = false;
      const data = new FormData();
      data.append("token", this.token);
      data.append("email", this.email);
      data.append("password", this.newPassword);
      data.append("password_confirmation", this.newPasswordConfirmation);
      try {
        await this.resetPassword(data);
        this.$router.push({
          name: 'signin', 
          params: {
            message: 'password-reset',
            email: this.email
          }
        });
      } catch (request) {
        this.handleError(request);
      }
    },
    handleError({response}) {
      try {
        this.error = [].concat(...Object.values(response.data.errors)).join(' ');
      } catch (error) {
        console.error(error)
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t("reset-password.title"),
    };
  },
};
</script>

<style scoped>
/* .reset-password-form input:first-child{
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .reset-password-form input:last-child{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  } */
</style>